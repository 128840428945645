//On Top page 
export const removeTrashesOnTopMedia = () => {
  if( location.pathname === "/" && location.search === ""){
    window.addEventListener( "load" , () => {
      (
        () => {
            let target = document.getElementById("FooterTopMedia");
            target.parentNode.removeChild( target );
          }
      )();
      (
        () => {
          let target = document.getElementById("Breadcrumb");
          target.style.display = "none";
        }
      )();
    })
  }
}
